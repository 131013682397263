import React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"

import Grid from "@material-ui/core/Grid"
import { Box } from "@material-ui/core"

import Wave from "react-wavify"

import Form from "../components/Form"

import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"

import SEO from "../components/seo"

import { Chart } from "react-google-charts"

import { Button } from "gatsby-theme-material-ui"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

const config = {
  fieldsConfig: [
    {
      id: 1,
      label: "Seu nome completo",
      fieldName: "name",
      type: "text",
      isRequired: true,
    },
    {
      id: 2,
      label: "Seu email para contato",
      fieldName: "email",
      type: "email",
      misRequired: true,
    },
    {
      id: 3,
      label: "Seu telefone para contato",
      fieldName: "phone",
      type: "text",
      isRequired: true,
    },
    {
      id: 4,
      label: "Nome da organização",
      fieldName: "organization",
      type: "text",
      isRequired: true,
    },
    {
      id: 5,
      label: "Sua função na organização",
      fieldName: "role",
      type: "text",
      isRequired: true,
    },
  ],
}

let Dialogcontent = ""

const Solutions = props => {
  const [openA, setOpenA] = React.useState(false)

  const handleClickOpenA = () => {
    setOpenA(true)
  }

  const handleCloseA = () => {
    setOpenA(false)
  }

  function OpenDialogWithComent(x) {
    Dialogcontent = x.target.getAttribute("datacomment")
    handleClickOpenA()
  }
  return (
    <>
      <SEO title="Soluções | Grafiame" />
      <main>
        {/* Capa inicial */}
        <Header />
        {/* Nossas diretrizes de segurança */}
        <Box px={5} py={10}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              container
              md={5}
              sm={12}
              justify="center"
              alignItems="center"
            >
              <Box m={5}>
                <img
                  className="me-img-content"
                  alt="Menino mexendo em um celular enquanto professora mexe em um computador."
                  src="/img/illustrations/getoutofthiscellboy .svg"
                ></img>
              </Box>
            </Grid>
            {/* </div> */}
            {/* <div className="me-flex-1 me-text-content item"> */}
            <Grid item md={7} sm={12}>
              <h2 className="me-h2-left">
                Nossas soluções<span className="me-the-end">.</span>
              </h2>
              <p>
                Como já falamos antes, nosso método consiste em entender sua
                organização antes de propor uma solução. Maaaas, isso não
                significa que não possamos dar um gostinho das nossas soluções
                aqui no site. Então aproveite! E não exite em entrar em contato
                para saber mais!
              </p>
            </Grid>
            {/* </div> */}
          </Grid>
        </Box>
        <Wave
          fill="#06d6a0"
          paused={false}
          options={{
            height: 2,
            amplitude: 45,
            speed: 0.08,
            points: 4,
          }}
        />

        {/* <div className="me-x-flex me-flex-wrap-reverse me-content-container"> */}
        <Box p={5} className="me-bg-verzul">
          <Grid container direction="row" justify="center" alignItems="center">
            {/* <div className="me-flex-1 me-text-content item"> */}
            <Grid item md={7} sm={12}>
              <h2 className="me-h2">
                Nossas correções
                <span className="me-the-end">.</span>
              </h2>
              <p>
                {
                  "Desenvolvemos uma tecnologia que permite uma correção de textos completamente detalhada. Com um sistema <<fofinho>> de caixinhas coloridas, basta clicar na tela para receber um comentário completo sobre aquele item e entender exatamente o erro ou acerto. Além dos comentários marcados no texto, há a possibilidade de produção do relatório de linguagem em formato de audio ou de texto que orienta o(a) escritor(a) a melhorar cada vez mais, fornecendo um plano de ação de como avançar na escrita."
                }
              </p>
            </Grid>

            {/* </div> */}
            {/* <div className="me-flex-1 item img"> */}
            <Grid
              item
              container
              md={5}
              sm={12}
              justify="center"
              alignItems="center"
            >
              <Box pb={1} maxWidth="90vw" minWidth="300px" width="450px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 2166 2206"
                  preserveAspectRatio="xMinYMin meet"
                  height="100%"
                  width="100%"
                >
                  <defs id="drawsvgConstraints" />
                  <g id="svgEditorBackgroundLayer">
                    <image
                      id="svgEditorBackgroundImage"
                      x="0"
                      y="0"
                      width="2166"
                      height="2206"
                      href="../img/redacao.jpg"
                      preserveAspectRatio="xMidYMid meet"
                    />
                  </g>
                  <path
                    d="M 974.4451904296875 321.38690185546875"
                    style={{ fill: "none", stroke: "black" }}
                    strokeWidth="2.571095466613769"
                    id="e4_path"
                  />
                   <rect
                    x="500"
                    y="20"
                    style={{
                      fill: "#9FEE92",
                      stroke: "none",
                      strokeWidth: "1px",
                      fillOpacity: "0.4",
                      fillRule: "nonzero",
                    }}
                    className="rectMark"
                    width="1500"
                    height="140"
                    datacomment="Título muito bom. Dialoga bem com seu texto."
                    onClick={OpenDialogWithComent}
                  />
                  <rect
                    x="120"
                    y="270"
                    style={{
                      fill: "#FFC259",
                      stroke: "none",
                      strokeWidth: "1px",
                      fillOpacity: "0.3",
                      fillRule: "nonzero",
                    }}
                    className="rectMark"
                    width="880"
                    height="140"
                    datacomment="Explique brevemente quem foi Descartes, para legitimar o repertório. Exemplo: Rene Descartes, filósofo francês do século XVIII, sempre..."
                    onClick={OpenDialogWithComent}
                  />
                  <rect
                    x="560"
                    y="400"
                    style={{
                      fill: "#FF0079",
                      stroke: "none",
                      strokeWidth: "1px",
                      fillOpacity: "0.3",
                      fillRule: "nonzero",
                    }}
                    className="rectMark"
                    width="460"
                    height="120"
                    datacomment="Desvio gramatical: faltou acentuar a palavra. Forma correta: 'violência'."
                    onClick={OpenDialogWithComent}
                  />
                  <rect
                    x="20"
                    y="680"
                    style={{
                      fill: "#FF0079",
                      stroke: "none",
                      strokeWidth: "1px",
                      fillOpacity: "0.3",
                      fillRule: "nonzero",
                    }}
                    className="rectMark"
                    width="540"
                    height="120"
                    datacomment="Erro de concordância nominal: o termo deve ficar no plural ('acordados') para que concorde com 'preceitos'."
                    onClick={OpenDialogWithComent}
                  />
                  <rect
                    x="681.34"
                    y="699.34"
                    style={{
                      fill: "#FF0079",
                      stroke: "none",
                      strokeWidth: "1px",
                      fillOpacity: "0.3",
                      fillRule: "nonzero",
                    }}
                    className="rectMark"
                    width="128.55"
                    height="84.85"
                    datacomment="Faltou uso de crase (à). Pela regra geral da crase: use diante de palavra feminina."
                    onClick={OpenDialogWithComent}
                  />
                  <rect
                    x="2060"
                    y="640"
                    style={{
                      fill: "#794AC4",
                      stroke: "none",
                      strokeWidth: "1px",
                      fillOpacity: "0.3",
                      fillRule: "nonzero",
                    }}
                    className="rectMark"
                    width="100"
                    height="980"
                    datacomment="Desenvolva melhor a ponte entre o repertório e a tese. Você cita Descartes, mas não relaciona de maneira efetiva com a derrubada de estátuas."
                    onClick={OpenDialogWithComent}
                  />
                  <rect
                    x="460"
                    y="830"
                    style={{
                      fill: "#FFC259",
                      stroke: "none",
                      strokeWidth: "1px",
                      fillOpacity: "0.3",
                      fillRule: "nonzero",
                    }}
                    className="rectMark"
                    width="660"
                    height="120"
                    datacomment="Como é a primeira vez que você cita o tema no texto, utilize 'monumentos históricos' em vez de só 'monumentos' para contextualizar melhor o leitor e não permitir dupla interpretação." 
                    onClick={OpenDialogWithComent}
                  />
                  <rect
                    x="1800"
                    y="1200"
                    style={{
                      fill: "#FFC259",
                      stroke: "none",
                      strokeWidth: "1px",
                      fillOpacity: "0.3",
                      fillRule: "nonzero",
                    }}
                    className="rectMark"
                    width="400"
                    height="140"
                    datacomment="Quem é esse alguém? E o que ele faz para ter sua simbologia questionada? Tente expecificar para com una figuras ou fatos marcantes."
                    onClick={OpenDialogWithComent}
                  />
                  <rect
                    x="5.14"
                    y="1378.11"
                    style={{
                      fill: "#FFC259",
                      stroke: "none",
                      strokeWidth: "1px",
                      fillOpacity: "0.3",
                      fillRule: "nonzero",
                    }}
                    className="rectMark"
                    width="890"
                    height="120"
                    datacomment="Quais são essas ideais? Por que essas ideais (da época) são negativas pela ótica da sociedade atual? Por que essas ideais motivam a derrubada de estátuas?"
                    onClick={OpenDialogWithComent}
                  />
                  <rect
                    x="1800"
                    y="1340"
                    style={{
                      fill: "#FF0079",
                      stroke: "none",
                      strokeWidth: "1px",
                      fillOpacity: "0.3",
                      fillRule: "nonzero",
                    }}
                    className="rectMark"
                    width="400"
                    height="140"
                    datacomment="Ilegível. Cuidado com a caligrafia!"
                    onClick={OpenDialogWithComent}
                  />
                  <rect
                    x="1000"
                    y="1340"
                    style={{
                      fill: "#FFC259",
                      stroke: "none",
                      strokeWidth: "1px",
                      fillOpacity: "0.3",
                      fillRule: "nonzero",
                    }}
                    className="rectMark"
                    width="400"
                    height="140"
                    datacomment="Opte por 'então ESSA' para garantir a coesão referencial à derrubada de monumentos."
                    onClick={OpenDialogWithComent}
                  />
                  <rect
                    x="20"
                    y="1670"
                    style={{
                      fill: "#FFC259",
                      stroke: "none",
                      strokeWidth: "1px",
                      fillOpacity: "0.5",
                      fillRule: "nonzero",
                    }}
                    className="rectMark"
                    width="200"
                    height="120"
                    datacomment="Inicie um novo parágrafo com um elemento coesivo como 'Diante disso', para ligar melhor seu texto."
                    onClick={OpenDialogWithComent}
                  />
                </svg>
              </Box>
              <Dialog
                open={openA}
                onClose={handleCloseA}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Comentário da redação"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {Dialogcontent}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseA} color="primary" autoFocus>
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
              <AudioPlayer
                // autoPlay
                src="/correcao_audio.mp3"
                // onPlay={e => console.log("onPlay")}
              />
            </Grid>
            {/* </div> */}
          </Grid>
        </Box>

        <Wave
          className="me-inverse"
          fill="#06d6a0"
          paused={false}
          options={{
            height: 4,
            amplitude: 45,
            speed: 0.08,
            points: 4,
          }}
        />

        <Box p={5}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              container
              md={5}
              sm={12}
              justify="center"
              alignItems="center"
            >
              {/*Vídeo RED */}
              <Box maxHeight="720px">
                <video
                  src="/Demonstracao_RED.mp4"
                  width="100%"
                  height="100%"
                  loop
                  autoPlay
                />
              </Box>
            </Grid>
            <Grid item md={7} sm={12}>
              <h2 className="me-h2-left">
                Nossa RED, a bot
                <span className="me-the-end">.</span>
              </h2>
              <p>
                É muito comum que os(as) aluno(as) tenham dúvidas durante a
                produção de texto: Acentos? Vírgulas? Ortografia? Como começo o
                texto? O que é resenha? E como o professor nem sempre está ao
                lado, a Grafiame propõe que o usuário tenha acesso à nossa
                central de ajuda RED de conteúdo para a produção textual, assim
                o (a) aluno(a) tira suas dúvidas durante a produção de texto
                como se tivesse um professor particular ao seu lado. Tire
                dúvidas mais simples e rápidas conversando com a RED — nossa
                inteligência artificial ou com o nosso suporte online. Caso a
                Red identifique complexidade na dúvida, imediatamente ela
                recomendará o atendimento por videoconferência agendado no
                plantão de dúvidas!
              </p>
            </Grid>
            {/* </div> */}
          </Grid>
        </Box>

        <Wave
          fill="#06d6a0"
          paused={false}
          options={{
            height: 4,
            amplitude: 45,
            speed: 0.08,
            points: 4,
          }}
        />

        <Box p={5} className="me-bg-verzul">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={7} sm={12}>
              <h2 className="me-h2-left">
                Análise de desempenho
                <span className="me-the-end">.</span>
              </h2>
              <p>
                Uma de nossas paixões também é a Matemática, por isso, a
                Grafiame traz uma análise de desempenho completa, por meio de
                números e gráficos para que o usuário meça seu desempenho e
                tenha referências de progresso . Mas fique tranquilo(a), somos
                completamente contra o uso abusivo de dados, então, somos muito
                transparentes com que tipo de dados estamos coletando de cada
                usuário e para que está sendo usado.
              </p>
            </Grid>

            <Grid
              item
              container
              md={5}
              sm={12}
              justify="center"
              alignItems="center"
            >
              <Box
                p={3}
                className="me-bg-branco-branco"
                boxShadow={3}
                borderRadius={10}
                maxWidth="90vw"
              >
                <Chart
                  width={"100%"}
                  height={"100%"}
                  chartType="Bar"
                  loader={<div>Carregando gráfico</div>}
                  data={[
                    ["Redação", "Nota redação", "Nota refeitura"],
                    ["1", 6.5, 7.0],
                    ["2", 7.5, 9.0],
                  ]}
                  options={{
                    // Material design options
                    chart: {
                      title: "Análise de desempenho",
                    },
                  }}
                  // For tests
                  rootProps={{ "data-testid": "2" }}
                />
              </Box>
            </Grid>
            {/* </div> */}
          </Grid>
        </Box>
        <Wave
          className="me-inverse"
          fill="#06d6a0"
          paused={false}
          options={{
            height: 4,
            amplitude: 45,
            speed: 0.08,
            points: 4,
          }}
        />
        {/* </div> */}
        {/* Entre em contato */}

        {/* <div className="me-x-flex me-flex-wrap"> */}
        <Box px={5} py={10}>
          <Grid container direction="row" justify="center" alignItems="center">
            {/* <div className="me-flex-1 me-text-content item"> */}
            <Grid item container md={7} sm={12}>
              <h2 className="me-h2-center">
                Vamos facilitar a Educação
                <span className="me-the-end">?</span>
              </h2>

              <Grid container justify="center">
                <Box my={5}>
                  <img
                    className="me-img-content"
                    alt="Aluno sendo ajuda por uma professora a subir uma escada, apoiada em um celular"
                    src="/img/illustrations/thisisahugephone.svg"
                  ></img>
                </Box>
              </Grid>
            </Grid>
            {/* </div> */}
            {/* <div className="me-flex-1 me-text-content item"> */}
            <Grid item container md={5} sm={12} justify="center">
              <Box p={3} boxShadow={3} borderRadius={10}>
                <Grid container style={{ maxWidth: 500 }}>
                  <Form config={config} />
                </Grid>
              </Box>
            </Grid>
            {/* </div> */}
          </Grid>
        </Box>
        {/* </div> */}
      </main>
      <Footer />
    </>
  )
}

export default Solutions
